


















































































































































































































































































































































































































import axios from 'axios';
import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Web3 from 'web3';

import { Category, Connection, Contact } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiSelectPro from '@/components/ui/UiSelectPro.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
// Validation
import ValidationSummaryNew from '@/components/validation/ValidationSummaryNew.vue';
import AnchorageCustodialInput from '@/components/wallets/AnchorageCustodialInput.vue';
// Exchanges
import BinanceExchangeInput from '@/components/wallets/BinanceExchangeInput.vue';
import BitfinexExchangeInput from '@/components/wallets/BitfinexExchangeInput.vue';
// Custodials
import BitGoCustodialInput from '@/components/wallets/BitGoCustodialInput.vue';
import BitstampExchangeInput from '@/components/wallets/BitstampExchangeInput.vue';
import BittrexExchangeInput from '@/components/wallets/BittrexExchangeInput.vue';
// Blockchain
import BlockchainInput from '@/components/wallets/BlockchainInput.vue';
import CircleExchangeInput from '@/components/wallets/CircleExchangeInput.vue';
import CoinbaseCustodyExchangeInput from '@/components/wallets/CoinbaseCustodyExchangeInput.vue';
import CoinbasePrimeExchangeInput from '@/components/wallets/CoinbasePrimeExchangeInput.vue';
import CoinbaseProExchangeInput from '@/components/wallets/CoinbaseProExchangeInput.vue';
import CoinbaseRetailExchangeInput from '@/components/wallets/CoinbaseRetailExchangeInput.vue';
import CryptoDotComExchangeInput from '@/components/wallets/CryptoDotComExchangeInput.vue';
import DefiOtherInput from '@/components/wallets/DefiOtherInput.vue';
import FireblocksCustodialInput from '@/components/wallets/FireblocksCustodialInput.vue';
import FoundryExchangeInput from '@/components/wallets/FoundryExchangeInput.vue';
import FtxExchangeInput from '@/components/wallets/FtxExchangeInput.vue';
import GeminiExchangeInput from '@/components/wallets/GeminiExchangeInput.vue';
import GroupOtherInput from '@/components/wallets/GroupOtherInput.vue';
import ItBitExchangeInput from '@/components/wallets/ItBitExchangeInput.vue';
import KrakenExchangeInput from '@/components/wallets/KrakenExchangeInput.vue';
import ManualOtherInput from '@/components/wallets/ManualOtherInput.vue';
import NyDigCustodialInput from '@/components/wallets/NyDigCustodialInput.vue';
import PaxosExchangeInput from '@/components/wallets/PaxosExchangeInput.vue';
import PrimetrustExchangeInput from '@/components/wallets/PrimetrustExchangeInput.vue';
import SfoxExchangeInput from '@/components/wallets/SfoxExchangeInput.vue';
import WalletAddedModal from '@/components/wallets/WalletAddedModal.vue';
import { WalletService } from '@/services/walletService';

import { baConfig } from '../../../config';
import { ApiSvcSubsidiary, OrganizationsApi } from '../../../generated/api-svc';
import { createWalletMutation } from '../../mutations/walletMutations';

@Component({
  components: {
    UiButton,
    UiSelect2,
    UiSelectPro,
    UiTextEdit,
    UiLoading,
    WalletAddedModal,
    BlockchainInput,
    BinanceExchangeInput,
    BitfinexExchangeInput,
    BittrexExchangeInput,
    FtxExchangeInput,
    PrimetrustExchangeInput,
    CoinbaseCustodyExchangeInput,
    CoinbaseProExchangeInput,
    CoinbasePrimeExchangeInput,
    CoinbaseRetailExchangeInput,
    KrakenExchangeInput,
    ItBitExchangeInput,
    BitstampExchangeInput,
    GeminiExchangeInput,
    PaxosExchangeInput,
    SfoxExchangeInput,
    BitGoCustodialInput,
    NyDigCustodialInput,
    AnchorageCustodialInput,
    FireblocksCustodialInput,
    ValidationSummaryNew,
    ManualOtherInput,
    GroupOtherInput,
    DefiOtherInput,
    CryptoDotComExchangeInput,
    CircleExchangeInput,
    FoundryExchangeInput,
  },
})
export default class CreateWalletNew extends BaseVue {
  @Prop({ default: [] })
  connections!: Connection[];

  @Prop({ required: true })
  type!: string;

  public isCreatingWallet = false;
  public hasExpandedDefaultFields = true;
  public subsidiaryId = '';

  public get walletTypes() {
    return [
      {
        type: 'blockchain',
        label: 'Blockchain',
        icon: 'fa-regular fa-coin-blank',
      },
      {
        type: 'exchange',
        label: 'Exchange',
        icon: 'fa-regular fa-piggy-bank',
      },
      {
        type: 'custodial',
        label: 'Custodial',
        icon: 'fa-regular fa-key-skeleton',
      },
      {
        type: 'other',
        label: 'Other',
        icon: 'fa-regular fa-wrench',
      },
    ];
  }

  public get others() {
    return [
      {
        value: 'manual',
        label: 'Manual',
        description: 'Manually add a wallet',
      },
      {
        value: 'group',
        label: 'Group',
        description: 'Group multiple wallets together',
      },
    ].sort((a, b) => (a.label as string).localeCompare(b.label as string));
  }

  public get blockchains() {
    return [
      {
        value: 'BTC',
        icon: require('../../assets/blockchains/btc.svg'),
        label: 'Bitcoin',
        description: 'BTC',
        enabled: !this.checkFeatureFlag('btc', this.features),
      },
      {
        label: this.$t('_btcWallet'),
        value: 'btc',
        description: this.$t('_btcWalletDescription'),
        icon: require('../../assets/blockchains/btc.svg'),
        enabled: this.checkFeatureFlag('btc', this.features),
      },
      // {
      //   value: 'EOS',
      //   icon: require('../../assets/blockchains/eos.png'),
      //   label: 'EOS Network',
      //   description: 'EOS',
      // },
      // {
      //   value: 'DASH',
      //   icon: require('../../assets/blockchains/dash.png'),
      //   label: 'DASH Network',
      //   description: this.$t('_dashWalletDescription'),
      //   enabled: this.checkFeatureFlag('dash-watch', this.features),
      //   networkId: 'dash',
      // },
      // {
      //   value: 'dashUTXO',
      //   icon: require('../../assets/blockchains/dash.png'),
      //   label: 'DASH Network',
      //   description: this.$t('_dashWalletDescription'),
      //   enabled: !this.checkFeatureFlag('dash-watch', this.features),
      //   networkId: 'dash',
      // },
      // {
      //   value: 'ZCASH',
      //   icon: require('../../assets/blockchains/zcash.png'),
      //   label: 'ZCASH Network',
      //   description: this.$t('_zcashWalletDescription'),
      //   enabled: this.checkFeatureFlag('zcash', this.features),
      //   networkId: 'zec',
      // },
      {
        value: 'celo',
        icon: require('../../assets/blockchains/celo.png'),
        label: this.$t('_celoWallet'),
        description: this.$t('_celoWalletDescription'),
        enabled: !this.checkFeatureFlag('celo', this.features),
      },
      {
        value: 'celoAbbs',
        icon: require('../../assets/blockchains/celo.png'),
        label: this.$t('_celoWallet'),
        description: this.$t('_celoWalletDescription'),
        enabled: this.checkFeatureFlag('celo', this.features),
        networkId: 'celo',
      },
      {
        value: 'polygon',
        icon: require('../../assets/blockchains/polygon.png'),
        label: this.$t('_polygonWallet'),
        description: this.$t('_polygonWalletDescription'),
        enabled: this.checkFeatureFlag('polygonOrig', this.features),
      },
      {
        label: this.$t('_polygonWallet'),
        value: 'polygonAbbs',
        description: this.$t('_polygonWalletDescription'),
        icon: require('../../assets/blockchains/polygon.svg'),
        enabled: !this.checkFeatureFlag('polygonOrig', this.features),
        networkId: 'polygon',
      },
      {
        value: 'optimism',
        icon: require('../../assets/blockchains/optimism.svg'),
        label: this.$t('_optimismWallet'),
        description: this.$t('_optimismWalletDescription'),
        networkId: 'op',
      },
      {
        value: 'avalancheAbbs',
        icon: require('../../assets/blockchains/avalanche.png'),
        label: this.$t('_avalancheWallet'),
        description: this.$t('_avalancheWalletDescription'),
        networkId: 'avaxc',
      },
      {
        value: 'avaxp',
        icon: require('../../assets/blockchains/avalanche.png'),
        label: this.$t('_avalanchePWallet'),
        description: this.$t('_avalanchePWalletDescription'),
        networkId: 'avaxp',
        enabled: this.checkFeatureFlag('avax-pchain', this.features),
      },
      {
        value: 'mina',
        icon: require('../../assets/blockchains/mina.png'),
        label: this.$t('_minaWallet'),
        description: this.$t('_minaWalletDescription'),
      },
      {
        label: this.$t('_bscWallet'),
        value: 'bscAbbs',
        icon: require('../../assets/blockchains/binance.png'),
        description: this.$t('_bscWalletDescription'),
        networkId: 'bsc',
      },
      // {
      //   value: 'heco',
      //   icon: require('../../assets/blockchains/heco.jpeg'),
      //   label: this.$t('_hecoWallet'),
      //   description: this.$t('_hecoWalletDescription'),
      // },
      {
        value: 'sol',
        icon: require('../../assets/blockchains/solana.svg'),
        label: this.$t('_solanaWallet'),
        description: this.$t('_solanaWalletDescription'),
      },
      {
        value: 'beacon',
        icon: require('../../assets/blockchains/beacon.svg'),
        label: this.$t('_beaconWallet'),
        description: this.$t('_beaconWalletDescription'),
        enabled: this.checkFeatureFlag('beacon', this.features),
      },
      {
        value: 'aptos',
        icon: require('../../assets/blockchains/aptos.png'),
        label: this.$t('_aptosWallet'),
        description: this.$t('_aptosWalletDescription'),
        networkId: 'apt',
      },
      // {
      //   value: 'terra',
      //   icon: require('../../assets/blockchains/terra.jpeg'),
      //   label: this.$t('_terraWallet'),
      //   description: this.$t('_terraWalletDescription'),
      // },
      {
        value: 'oasis',
        icon: require('../../assets/blockchains/oasis.png'),
        label: this.$t('_oasisWallet'),
        description: this.$t('_oasisWalletDescription'),
        networkId: 'rose',
      },
      {
        value: 'ftm',
        icon: require('../../assets/blockchains/ftm.svg'),
        label: this.$t('_ftmWallet'),
        description: this.$t('_ftmWalletDescription'),
        enabled: this.checkFeatureFlag('ftm', this.features),
      },
      {
        value: 'filecoin',
        icon: require('../../assets/blockchains/filecoin.png'),
        label: this.$t('_filecoinWallet'),
        description: this.$t('_filecoinWalletDescription'),
        networkId: 'fil',
      },
      {
        value: 'flow',
        icon: require('../../assets/blockchains/flow.png'),
        label: this.$t('_flowWallet'),
        description: this.$t('_flowWalletDescription'),
      },
      {
        value: 'casper',
        icon: require('../../assets/blockchains/casper.png'),
        label: this.$t('_casperWallet'),
        description: this.$t('_casperWalletDescription'),
      },
      {
        value: 'stx',
        icon: require('../../assets/blockchains/stx.png'),
        label: this.$t('_stxWallet'),
        description: this.$t('_stxWalletDescription'),
      },
      // {
      //   value: 'lrc',
      //   icon: require('../../assets/blockchains/lrc.png'),
      //   label: this.$t('_lrcWallet'),
      //   description: this.$t('_lrcWalletDescription'),
      //   enabled: this.checkFeatureFlag('loopring', this.features),
      // },
      {
        value: 'immutable',
        icon: require('../../assets/blockchains/immutable.png'),
        label: this.$t('_immutableWallet'),
        description: this.$t('_immutableWalletDescription'),
        enabled: this.checkFeatureFlag('immutable', this.features),
        networkId: 'imx',
      },
      {
        value: 'near',
        icon: require('../../assets/blockchains/near.png'),
        label: this.$t('_nearWallet'),
        description: this.$t('_nearWalletDescription'),
        enabled: this.checkFeatureFlag('near', this.features),
      },
      {
        value: 'klaytn',
        label: this.$t('_klaytnWallet'),
        description: this.$t('_klaytnWalletDescription'),
        icon: require('../../assets/blockchains/klaytn.svg'),
        enabled: this.checkFeatureFlag('klay', this.features),
        networkId: 'klay',
      },
      {
        value: 'kaia',
        icon: require('../../assets/blockchains/kaia.svg'),
        label: this.$t('_kaiaWallet'),
        description: this.$t('_kaiaWalletDescription'),
        enabled: this.checkFeatureFlag('kaia', this.features),
        networkId: 'kaia',
      },
      {
        label: this.$t('_arbitrumWallet'),
        icon: require('../../assets/blockchains/arbitrum.png'),
        value: 'arb',
        description: this.$t('_arbitrumWalletDescription'),
      },
      {
        label: this.$t('_bchWallet'),
        value: 'bch',
        description: this.$t('_bchWalletDescription'),
        icon: require('../../assets/blockchains/bch.svg'),
        enabled: this.checkFeatureFlag('bch', this.features),
      },
      {
        label: this.$t('_cosmosWallet'),
        value: 'cosmos',
        description: this.$t('_cosmosWalletDescription'),
        icon: require('../../assets/blockchains/cosmos.svg'),
        enabled: this.checkFeatureFlag('cosmos', this.features),
      },
      // {
      //   label: this.$t('_dogeWallet'),
      //   value: 'doge',
      //   description: this.$t('_dogeWalletDescription'),
      //   icon: require('../../assets/blockchains/doge.svg'),
      //   enabled: this.checkFeatureFlag('doge', this.features),
      // },
      {
        label: this.$t('_ethWallet'),
        value: 'ETH',
        description: this.$t('_ethWalletDescription'),
        icon: require('../../assets/blockchains/eth.svg'),
        enabled: this.checkFeatureFlag('eth-watch', this.features),
      },
      {
        label: this.$t('_ethRollupWallet'),
        value: 'eth',
        description: this.$t('_ethRollupWalletDescription'),
        icon: require('../../assets/blockchains/eth.svg'),
        enabled: !this.checkFeatureFlag('eth-watch', this.features),
      },
      {
        label: this.$t('_gnosisWallet'),
        value: 'gnosis',
        description: this.$t('_gnosisWalletDescription'),
        icon: require('../../assets/blockchains/gnosis.svg'),
      },
      {
        label: this.$t('_auroraWallet'),
        value: 'aurora',
        description: this.$t('_auroraWalletDescription'),
        icon: require('../../assets/blockchains/aurora.svg'),
        enabled: this.checkFeatureFlag('aurora', this.features),
      },
      {
        label: this.$t('_baseWallet'),
        value: 'base',
        description: this.$t('_baseWalletDescription'),
        icon: require('../../assets/blockchains/base.svg'),
      },
      {
        label: this.$t('_hederaWallet'),
        value: 'hbar',
        description: this.$t('_hederaWalletDescription'),
        icon: require('../../assets/blockchains/hedera.svg'),
      },
      // {
      //   label: this.$t('_kavaWallet'),
      //   value: 'kava',
      //   description: this.$t('_kavaWalletDescription'),
      //   icon: require('../../assets/blockchains/kava.svg'),
      //   enabled: this.checkFeatureFlag('kava', this.features),
      // },
      // {
      //   label: this.$t('_kusamaWallet'),
      //   value: 'kusama',
      //   description: this.$t('_kusamaWalletDescription'),
      //   icon: require('../../assets/blockchains/kusama.svg'),
      //   enabled: this.checkFeatureFlag('kusama', this.features),
      //   networkId: 'ksm',
      // },
      // {
      //   label: this.$t('_ltcWallet'),
      //   value: 'ltc',
      //   description: this.$t('_ltcWalletDescription'),
      //   icon: require('../../assets/blockchains/ltc.svg'),
      //   enabled: this.checkFeatureFlag('ltc', this.features),
      // },
      {
        label: this.$t('_osmosisWallet'),
        value: 'osmosis',
        description: this.$t('_osmosisWalletDescription'),
        icon: require('../../assets/blockchains/osmosis.svg'),
        enabled: this.checkFeatureFlag('osmo', this.features),
        networkId: 'osmo',
      },
      // {
      //   label: this.$t('_polkadotWallet'),
      //   value: 'polkadot',
      //   description: this.$t('_polkadotWalletDescription'),
      //   icon: require('../../assets/blockchains/polkadot.svg'),
      //   enabled: this.checkFeatureFlag('polkadot', this.features),
      //   networkId: 'dot',
      // },
      {
        label: this.$t('_stellarWallet'),
        value: 'xlm',
        description: this.$t('_stellarWalletDescription'),
        icon: require('../../assets/blockchains/stellar.svg'),
        enabled: this.checkFeatureFlag('xlm', this.features),
        network: 'xlm',
      },
      {
        label: this.$t('_cantonWallet'),
        value: 'canton',
        description: this.$t('_cantonWalletDescription'),
        icon: require('../../assets/blockchains/canton.png'),
        // enabled: this.checkFeatureFlag('canton', this.features),
        network: 'canton',
      },
      {
        label: this.$t('_zetaWallet'),
        value: 'zeta',
        description: this.$t('_zetaWalletDescription'),
        icon: require('../../assets/blockchains/zeta.svg'),
        enabled: this.checkFeatureFlag('zeta', this.features),
        network: 'zeta',
        notes: 'Please ensure that when you add this wallet you add it using Zeta Address, not the EVM address.',
      },
      {
        label: this.$t('_xrpWallet'),
        value: 'xrp',
        description: this.$t('_xrpWalletDescription'),
        icon: require('../../assets/blockchains/xrp.svg'),
      },
      {
        label: this.$t('_dexalotWallet'),
        value: 'alot',
        description: this.$t('_dexalotWalletDescription'),
        icon: require('../../assets/blockchains/dexalot.svg'),
        // enabled: this.checkFeatureFlag('dexalot', this.features),
        network: 'alot',
        notes: 'Dexalot, a subnet of Avalanche',
      },
      {
        label: this.$t('_polymeshWallet'),
        value: 'polyx',
        description: this.$t('_polymeshWalletDescription'),
        icon: require('../../assets/blockchains/polymesh.svg'),
        enabled: this.checkFeatureFlag('polyx', this.features),
        network: 'polyx',
      },
    ]
      .sort((a, b) => (a.label as string).localeCompare(b.label as string))
      .filter((m) => m.enabled === undefined || m.enabled === true);
  }

  public get exchanges() {
    return [
      {
        value: 'binance',
        icon: '/binance.png',
        label: 'Binance',
      },
      {
        value: 'bitfinex',
        icon: '/bitfinex.png',
        label: 'Bitfinex',
      },
      // FIXME: Temporarily disabled until validation is implemented
      // {
      //   value: 'bittrex',
      //   icon: '/bittrex.png',
      //   label: 'Bittrex',
      // },
      {
        value: 'coinbaseExchange',
        icon: '/coinbasepro.png',
        label: 'Coinbase Exchange',
      },
      {
        value: 'coinbaseprime',
        icon: '/coinbaseprime.png',
        label: 'Coinbase Prime',
      },
      {
        value: 'coinbaseretail',
        icon: '/coinbase-retail.png',
        label: 'Coinbase Retail',
      },
      {
        value: 'kraken',
        icon: '/kraken.png',
        label: 'Kraken',
      },
      // FIXME: Temporarily disabled, current input has incorrect fields
      // {
      //   value: 'itbit',
      //   icon: '/itbit.png',
      //   label: 'ItBit',
      // },
      {
        value: 'bitstamp',
        icon: '/bitstamp.png',
        label: 'Bitstamp',
      },
      {
        value: 'gemini',
        icon: '/gemini.png',
        label: 'Gemini',
      },
      {
        value: 'paxos',
        icon: '/paxos.png',
        label: 'Paxos',
      },
      {
        value: 'sfox',
        icon: '/sfox.png',
        label: 'SFOX',
      },
      {
        value: 'crypto.com',
        icon: '/crypto-com.png',
        label: 'Crypto.com',
        enabled: this.checkFeatureFlag('crypto.com', this.features),
      },
      {
        value: 'circle',
        icon: '/circle.png',
        label: 'Circle',
        enabled: this.checkFeatureFlag('circle', this.features),
      },
      {
        value: 'foundry',
        icon: '/foundry.png',
        label: 'Foundry',
        enabled: this.checkFeatureFlag('beta-integrations', this.features),
      },
    ]
      .sort((a, b) => (a.label as string).localeCompare(b.label as string))
      .filter((m) => m.enabled === undefined || m.enabled === true);
  }

  public get custodials() {
    return [
      {
        value: 'bitgo',
        icon: '/bitgo.png',
        label: 'BitGo',
      },
      // {
      //   value: 'nydig',
      //   icon: '/nydig.jpeg',
      //   label: 'NyDig',
      // },
      {
        value: 'anchorage',
        icon: '/anchorage.png',
        label: 'Anchorage',
      },
      {
        value: 'fireblocks',
        icon: '/fireblocks.png',
        label: 'Fireblocks',
      },
    ].sort((a, b) => (a.label as string).localeCompare(b.label as string));
  }

  public get isAdditionalWalletsValid() {
    return this.additionalNetworks.every((n) => !n.createWallet || this.validateWalletName(n.userInputName));
  }

  public get completedDefaultFields() {
    const defaults: Record<string, string> = {};
    if (!this.defaultFields.accountingConnection) return defaults;

    for (const key of Object.keys(this.defaultFields)) {
      const defaultVal = this.defaultFields[key];
      if (defaultVal) defaults[key] = defaultVal;
    }

    return defaults;
  }

  public headers = [
    {
      id: 'wallet',
      label: 'Wallet',
      defaultVisibility: true,
    },
    {
      id: 'address',
      label: 'Address',
      defaultVisibility: true,
    },
    {
      id: 'balance',
      label: 'Balance',
      defaultVisibility: true,
    },
    {
      id: 'bulk-send',
      label: 'Bulk Send',
      defaultVisibility: true,
    },
  ];

  public newWalletType = this.getWalletType();

  public newTokenType = '';
  public newWalletName = '';
  public newWalletData: any = {};
  public isValidInput = false;
  public isValidating = false;
  public isValidated = false;
  public showDoneModal = false;
  public isLoadingSubs = false;
  public subs: ApiSvcSubsidiary[] = [];

  public defaultFields: { [key: string]: string | null } = {
    accountingConnection: null,
    defaultInflowContact: null,
    defaultInflowCategory: null,
    defaultOutflowContact: null,
    defaultOutflowCategory: null,
    defaultFeeContact: null,
    defaultFeeCategory: null,
  };

  public otherChainsLoading = false;
  public otherChainsExist = false;
  public otherChainsList: string[] = [];
  public additionalNetworks: {
    label: string;
    value: string;
    userInputName: string;
    createWallet: boolean;
    logo: any;
    subsidiaryId?: string;
  }[] = [];

  // getOrElse use local for development env
  private addressSvcUrl = baConfig.addressSvcUrl ?? 'http://localhost:3000';

  public walletRoles: { id: string; name: string }[] | undefined = [];
  public walletRoleId = null;
  public selectedWalletRoleId = '';
  public walletNotes = '';

  $refs!: {
    connectionValidation: any;
  };

  public get isLoading() {
    return this.isLoadingSubs;
  }

  public validateWalletName(name: string) {
    return name.length >= 3;
  }

  public getWalletType() {
    return 'blockchain';
  }

  public validateSubsidiary(subsidiaryId: string) {
    return (
      (this.checkFeatureFlag('subsidiaries', this.features) && !!subsidiaryId) ||
      !this.checkFeatureFlag('subsidiaries', this.features)
    );
  }

  public validateAddress(address: string) {
    return address.length > 0 && address.indexOf(' ') === -1;
  }

  public get tokenTypes() {
    if (this.newWalletType === 'blockchain') return this.blockchains;
    if (this.newWalletType === 'exchange') return this.exchanges;
    if (this.newWalletType === 'custodial') return this.custodials;
    if (this.newWalletType === 'other') return this.others;
    return [];
  }

  get categories() {
    if (!this.defaultFields.accountingConnection) return [];

    return this.$store.getters['categories/ENABLE_CATEGORIES']
      .filter((c: Category) => c.accountingConnectionId === this.defaultFields.accountingConnection)
      .map((c: Category) => ({ id: c.id, name: `${c.code} ${c.name}`, type: c.type }));
  }

  get contacts() {
    if (!this.defaultFields.accountingConnection) {
      return [];
    }
    return this.$store.getters['contacts/ENABLED_CONTACTS']
      .filter((c: Contact) => c.accountingConnectionId === this.defaultFields.accountingConnection)
      .map((c: Contact) => ({ id: c.id, name: c.name, caption: `${c.type} - ${this._getRemoteID(c)}` }));
  }

  private _getRemoteID(item: Contact) {
    const split = item.id.split('.');
    split.shift();
    return split.join('.');
  }

  public onInputChange(data: any) {
    this.resetValidation();
    this.newWalletData = data;
    this.checkOtherNetworks();
  }

  public async checkOtherNetworks() {
    this.otherChainsLoading = true;
    // we're wrapping this guy in a try/catch because it's a nice to have. Not likely to fail,
    // but if something blows up in address-svc, it really shouldn't block the user from adding a wallet.
    try {
      if (this.newWalletType === 'blockchain') {
        const web3 = new Web3();
        const address: string = this.newWalletData.walletAddress;
        if (web3.utils.isAddress(address)) {
          // reset state.
          this.otherChainsList = [];
          this.additionalNetworks = [];
          this.otherChainsExist = false;
          const otherBlockchainsResp = await axios.get<string[]>(
            this.addressSvcUrl.concat(`/address/${address}/active-networks`)
          );
          if (otherBlockchainsResp.status === 200) {
            otherBlockchainsResp.data.forEach((b) => {
              // remove the currently selected network from the list of other existing.
              if (b !== (this.bwNetworkId[this.newTokenType.toLowerCase()] ?? this.newTokenType.toLowerCase())) {
                this.otherChainsList.push(b);
                this.otherChainsExist = true;
              }
            });
            const existingNetworksForThisAddress: string[] = [];
            // find other wallets with the same address as the input
            (this.$store.state.wallets.wallets ?? []).forEach((m) => {
              if (m.networkId) {
                const useNetwork = this.bwNetworkId[m.networkId] ?? m.networkId;
                if (m.address?.toLowerCase() === address.toLowerCase()) {
                  existingNetworksForThisAddress.push(useNetwork);
                } else if (
                  m.addresses &&
                  m.addresses.map((m) => m?.toLowerCase() ?? '').includes(address.toLowerCase())
                ) {
                  existingNetworksForThisAddress.push(useNetwork);
                }
              }
            });
            // remove those wallets from our list
            this.otherChainsList = this.otherChainsList.filter((f) => !existingNetworksForThisAddress.includes(f));

            // push a suggestion to create each remaining network.
            this.otherChainsList.forEach((chain) => {
              const blockchain = this.getBlockchain(this.blockchainValue[chain] ?? chain);
              if (blockchain) {
                this.additionalNetworks.push({
                  label: blockchain.label.toString(),
                  value: blockchain.value,
                  userInputName: `${this.newWalletName} [${chain.toUpperCase()}]`,
                  createWallet: false,
                  logo: blockchain.icon,
                  subsidiaryId: this.subsidiaryId || undefined,
                });
              }
            });
          }
        } else {
          // reset state if not a valid address.
          this.otherChainsList = [];
          this.additionalNetworks = [];
          this.otherChainsExist = false;
        }
      }
    } catch (e) {
      console.log('error checking other networks', e);
      this.showErrorSnackbar('Error checking other networks, you can still add this wallet.');
    }

    this.otherChainsLoading = false;
  }

  private bwNetworkId: { [value: string]: string } = {
    avalanche: 'avaxc',
    ETH: 'eth',
    ethwatch: 'eth',
    ethrollup: 'eth',
    avalancheabbs: 'avaxc',
    avaxp: 'avaxp',
    celoabbs: 'celo',
  };

  private blockchainValue: { [value: string]: string } = {
    avaxc: 'avalanche',
    avaxp: 'avaxp',
    eth: 'ETH',
  };

  public getNetworkId(tokenValue: string) {
    const networkId =
      this.blockchains.find(
        (f) => f.value.toLowerCase() === tokenValue.toLowerCase() && (f.enabled === true || f.enabled === undefined)
      )?.networkId ?? tokenValue;
    return networkId;
  }

  public getBlockchain(network: string) {
    return this.blockchains.find(
      (f) => f.value.toLowerCase() === network.toLowerCase() && (f.enabled === true || f.enabled === undefined)
    );
  }

  public onInputValidationChange(valid: false) {
    this.resetValidation();
    this.isValidInput = valid;
  }

  public async validate() {
    this.isValidating = true;
    const validation = await this.$refs.connectionValidation.validate(this.newWalletData);
    this.isValidated = validation.success;
    this.isValidating = false;
  }

  public get newTokenTypeLabel() {
    const tokenTypes: any[] = this.tokenTypes;
    const tokenType = tokenTypes.find((type: any) => type.value === this.newTokenType);
    return tokenType ? tokenType.label : '';
  }

  public async createWallets() {
    await this.createWallet();
    for (const net of this.additionalNetworks) {
      const tokenType = net.value;
      const name = net.userInputName;
      const address = this.newWalletData.walletAddress.trim();
      const subsidiaryId = this.checkFeatureFlag('subsidiaries', this.features) ? net.subsidiaryId : undefined;
      if (tokenType && name && address && net.createWallet) {
        await this.createWallet(tokenType, name, address, subsidiaryId);
      }
    }
    // reset after all wallets are created
    this.otherChainsList = [];
    this.additionalNetworks = [];
    this.otherChainsExist = false;
  }

  public async createWallet(_tokenType?: string, _name?: string, _address?: string, _subsidiaryId?: string) {
    this.isCreatingWallet = true;
    try {
      const subsidiaryId =
        this.checkFeatureFlag('subsidiaries', this.features) && this.subsidiaryId
          ? _subsidiaryId || this.subsidiaryId
          : undefined;

      if (this.newWalletType === 'blockchain') {
        let vars: any;
        const watchWallets = ['BTC', 'ETH', 'EOS', 'DASH'];
        const addressWallets = ['celo', 'polygon', 'stx', 'bsc']; // heco deprecated for now
        const accountBasedWallets = [
          'mina',
          'sol',
          'terra',
          'oasis',
          'filecoin',
          'flow',
          'ftm',
          'casper',
          'immutable',
          'klaytn',
          'near',
          // new ones
          'avalancheAbbs',
          'celoAbbs',
          'eth',
          'aptos',
          'kusama',
          'bch',
          'bscAbbs',
          'hbar',
          'polkadot',
          'xlm',
          'canton',
          'polygonAbbs',
          'arb',
          'ltc',
          'btc',
          'dashUTXO',
          'doge',
          'kava',
          'optimism',
          'osmosis',
          'xrp',
          'gnosis',
          'aurora',
          'base',
          'cosmos',
          'zeta',
          'alot',
          'polyx',
          'avaxp',
          'kaia',
        ];
        const name = _name ?? this.newWalletName.trim();
        const address = _address ?? this.newWalletData.walletAddress.trim();
        const addressType = this.newWalletData.addressType;
        const orgId = this.$store.state.currentOrg.id;
        const prems = [
          {
            userId: this.$store.state.user?.id,
            role: 4,
          },
        ];

        if (watchWallets.includes(_tokenType ?? this.newTokenType)) {
          const wallet = {
            name: name,
            type: 'watch',
            watch: {
              coin: _tokenType ?? this.newTokenType,
              type: addressType === 'Simple' ? 'addressSet' : 'hd',
              addresses: addressType === 'Simple' ? [address] : undefined,
              derivationKey: addressType !== 'Simple' ? address : undefined,
            },
            subsidiaryId,
            ...this.completedDefaultFields,
          };

          vars = {
            wallet,
            orgId,
            prems,
          };
        } else if (addressWallets.includes(_tokenType ?? this.newTokenType)) {
          const addressBasedBlockchain = {
            address: address,
            // rollupConfig (need this later)
          };
          const wallet = {
            name: name,
            subsidiaryId,
            type: _tokenType ?? this.newTokenType,
            addressBasedBlockchain,
            ...this.completedDefaultFields,
          };
          vars = {
            orgId,
            wallet,
            prems,
          };
        } else if (accountBasedWallets.includes(_tokenType ?? this.newTokenType)) {
          const orgId = this.$store.state.currentOrg.id;
          const immutableXConfig = this.newWalletData.immutableXConfig;

          let rollupConfig: any;
          if (this.newWalletData.rollup) {
            if (!this.newWalletData.rollupSelector || !this.newWalletData.rollupPeriod) {
              this.showErrorSnackbar('If setting rollups, must select type and period');
              return;
            }

            rollupConfig = {
              rollupPeriod: this.newWalletData.rollupPeriod,
              rollupSelector: this.newWalletData.rollupSelector,
              rollupRevenue: this.newWalletData.rollupRevenue,
              rollupTokenRevenue: this.newWalletData.rollupTokenRevenue,
              rollupFee: this.newWalletData.rollupFee,
              rollupTokenRemittance: this.newWalletData.rollupTokenRemittance,
              rollupRemittance: this.newWalletData.rollupRemittance,
              rollupAll: this.newWalletData.rollupAll,
              accountCheck: this.newWalletData.accountCheck,
              seperateByAccount: this.newWalletData.seperateByAccount,
              logSelector: this.newWalletData.logSelector,
              seperateByLog: this.newWalletData.seperateByLog,
            };
          }

          const accountBasedBlockchain = {
            address: address,
            networkId: this.getNetworkId(_tokenType ?? this.newTokenType),
            rollupConfig,
            metadata: {
              ...this.newWalletData.metadata,
              immutableXConfig,
            },
          };

          const wallet = {
            type: 'accountBasedBlockchain',
            name: name,
            subsidiaryId,
            accountBasedBlockchain,
            ...this.completedDefaultFields,
            walletRoleId: this.walletRoleId,
          };

          vars = {
            orgId,
            wallet,
            prems,
          };
        } else {
          // UnRecognized Wallet Type
          this.isCreatingWallet = false;
          return;
        }

        // add wallet flags tracking mode
        if (this.newWalletData.trackingMode || this.newWalletData.syncStartDateSEC) {
          vars.wallet = {
            ...vars.wallet,
            flags: {
              ...(this.newWalletData.trackingMode && { trackingMode: this.newWalletData.trackingMode }),
              ...(this.newWalletData.syncStartDateSEC && { syncStartDateSEC: this.newWalletData.syncStartDateSEC }),
            },
          };
        }

        await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
              createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        });
      } else if (this.newWalletType === 'exchange' || this.newWalletType === 'custodial') {
        const name = _name ?? this.newWalletName.trim();
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          create: { ...this.newWalletData, ...this.completedDefaultFields, subsidiaryId, name },
        };
        const res = await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create) {
                success
                errors
                message
              }
            }
          `,
          // Parameters
          variables: vars,
        });

        if (!res.data.createConnection.success) {
          console.log(res.data.createConnection.errors);
          if (res.data.createConnection.errors.length > 0) {
            let errs = '';
            for (const i in res.data.createConnection.errors) {
              errs = errs + res.data.createConnection.errors[i] + '\n';
            }
            this.showErrorSnackbar(errs);
          } else {
            this.showErrorSnackbar('Error setting up connection.');
          }
          return;
        }
      } else if (this.newWalletType === 'other' && this.newTokenType === 'manual') {
        const orgId = this.$store.state.currentOrg.id;
        const prems = [
          {
            userId: this.$store.state.user?.id,
            role: 4,
          },
        ];

        const manual = {
          description: this.newWalletData.description,
        };
        const wallet = {
          name: this.newWalletName,
          subsidiaryId,
          type: this.newTokenType,
          manual,
          ...this.completedDefaultFields,
          walletRoleId: this.walletRoleId,
        };

        const variables = {
          orgId,
          wallet,
          prems,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
              createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
                id
              }
            }
          `,
          variables,
        });
      } else if (this.newWalletType === 'other' && this.newTokenType === 'group') {
        const orgId = this.$store.state.currentOrg.id;
        const prems = [
          {
            userId: this.$store.state.user?.id,
            role: 4,
          },
        ];
        const wallet = {
          name: this.newWalletName,
          subsidiaryId,
          type: 'group',
          group: {
            children: this.newWalletData.childWallets,
          },
          ...this.completedDefaultFields,
          walletRoleId: this.walletRoleId,
        };
        const variables = {
          wallet,
          orgId,
          prems,
        };
        await this.$apollo.mutate({
          mutation: createWalletMutation,
          variables,
        });
      } else if (this.newWalletType === 'other' && this.newTokenType === 'defi') {
        const orgId = this.$store.state.currentOrg.id;
        const prems = [
          {
            userId: this.$store.state.user?.id,
            role: 4,
          },
        ];
        const defi = {
          connectionId: undefined,
          networkId: this.newWalletData.networkId || undefined,
          isSyncEnabled: !!this.newWalletData.isSyncEnabled,
          walletAddress: this.newWalletData.walletAddress.trim(),
          vaultAddress: this.newWalletData.vaultAddress.trim(),
          tokenId: this.newWalletData.tokenId,
          groupId: this.newWalletData.groupId,
        };

        const wallet = {
          name: this.newWalletName,
          subsidiaryId,
          type: 'defi',
          defi,
          ...this.completedDefaultFields,
          walletRoleId: this.walletRoleId,
        };

        const variables = {
          orgId,
          wallet,
          prems,
        };
        await this.$apollo.mutate({
          mutation: createWalletMutation,
          variables,
        });
      }
      this.showDoneModal = true;
    } catch (err) {
      console.log('err', err);
    } finally {
      this.isCreatingWallet = false;
    }
  }

  public get needValidationCheck() {
    if (this.newWalletType === 'blockchain') return false;
    const validationConnections = ['bittrex', 'coinbaseprime', 'fireblocks', 'foundry', 'bitgo', 'bitfinex'];
    return validationConnections.includes(this.newTokenType);
  }

  public get newNetworkIcon() {
    if (this.newWalletType === 'blockchain') {
      return this.blockchains.find((e) => e.value === this.newTokenType)?.icon;
    }
    if (this.newWalletType === 'exchange') {
      return this.exchanges.find((e) => e.value === this.newTokenType)?.icon;
    }
    if (this.newWalletType === 'custodial') {
      return this.custodials.find((e) => e.value === this.newTokenType)?.icon;
    }
  }

  public get newNetworkIconArray() {
    if (this.newWalletType === 'blockchain') {
      const baseArr = [];
      const firstLogo = this.blockchains.find((e) => e.value === this.newTokenType)?.icon;
      if (firstLogo) {
        baseArr.push(firstLogo);
      }
      this.additionalNetworks.flatMap((m) => {
        if (m.createWallet && m.logo) {
          baseArr.push(m.logo);
        }
      });
      if (baseArr.length > 0) {
        return baseArr;
      }
    }
  }

  async mounted() {
    if (this.checkFeatureFlag('advanced-defi-wallet-creation', this.features)) {
      this.others.push({
        value: 'defi',
        label: 'DeFi',
        description: this.$t('_defiWalletDescription').toString(),
      });
      this.others.sort((a, b) => (a.label as string).localeCompare(b.label as string));
    }
    if (this.checkFeatureFlag('subsidiaries', this.features)) {
      this.isLoadingSubs = true;
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
        if (resp.status === 200) {
          this.subs = resp.data;
        } else {
          throw new Error('Problem loading subs');
        }
      } catch (e) {
        this.showErrorSnackbar('failed to load subsidiaries');
      } finally {
        this.isLoadingSubs = false;
      }
    }

    // load wallet roles
    if (this.checkFeatureFlag('soda-report')) this.getWalletRoles();
  }

  public closeDoneModal(extraAction?: string) {
    this.showDoneModal = false;
    this.newWalletType = 'blockchain';
    this.newTokenType = '';
    this.newWalletName = '';
    this.newWalletData = {};
    this.$refs.connectionValidation.reset();
    this.$root.$emit('refresh-wallets');
    if (extraAction === 'list') {
      this.$emit('back', true);
    }
  }

  public onWalletTypeChange() {
    this.resetValidation();
    this.resetWalletData();
    this.walletNotes = this.blockchains.find((bc) => bc.value === this.newTokenType)?.notes || '';
  }

  public async getWalletRoles() {
    const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
    this.walletRoles = await walletSvc.getWalletRoles(this.$store.state.currentOrg.id);
  }

  public async addWalletRole(walletRoleName: string) {
    if (!walletRoleName) return;
    const orgId = this.$store.state.currentOrg.id;
    const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
    const walletRoleId = await walletSvc.createRole(orgId, walletRoleName);
    if (walletRoleId) {
      this.walletRoles = [
        ...(this.walletRoles ?? []),
        {
          id: walletRoleId,
          name: walletRoleName,
        },
      ];

      // default selected wallet role id
      this.selectedWalletRoleId = walletRoleId;
      // this.walletRoleId = walletRoleId;
    }
  }

  public resetValidation() {
    this.isValidated = false;
  }

  public resetWalletData() {
    this.isValidInput = false;
    this.newWalletData = {};
  }

  public onNewWalletTypeChanged(walletType: string) {
    this.newWalletType = walletType;
    this.newWalletName = '';
    this.newTokenType = '';
    this.onWalletTypeChange();
  }
}
