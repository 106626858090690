


























































































































































import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { IsValidAddress } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import UiRadioGroup from '../../components/ui/UiRadioGroup.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import { validWalletAddress } from '../../queries/walletQueries';
import UiButton from '../ui/UiButton.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiDatePicker2 from '../ui/UiDatePicker2.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTooltip from '../ui/UiTooltip.vue';

type MetadataField = { id: string; label: string; featureFlag?: string };
type BlockchainValidations = { regex: string; validationMessage: string };

@Component({
  components: {
    UiTextEdit,
    UiRadioGroup,
    UiCheckbox,
    UiSelect2,
    UiButton,
    UiDatePicker2,
    UiTooltip,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  @Prop({ required: true })
  public readonly blockchain!: string;

  @Prop({ default: false })
  public readonly isLoadingMultiChainCheck?: boolean;

  @Prop({ default: false })
  public readonly useSubsidiaries!: boolean;

  @Prop({ default: false })
  public readonly isLoadingSubs!: boolean;

  public walletAddress = '';
  public isValid = false;
  public debouncedValidate = _.debounce(this.validateWalletAddress.bind(this), 1000);
  public immutableXConfig = 'normal';
  public rollup = false;
  public rollupSelector = '';
  public rollupSelectors = ['revenue', 'fees', 'custom'];
  public rollupPeriod = '';
  public rollupPeriods = ['hour'];
  public rollupRevenue = false;
  public rollupTokenRevenue = false;
  public rollupFee = false;
  public rollupTokenRemittance = false;
  public rollupRemittance = false;
  public rollupAll = false;
  public accountCheck: string[] = [];
  public seperateByAccount = false;
  public logSelector: string[] = [];
  public watchWallets = ['BTC', 'ETH', 'EOS', 'DASH'];
  public heirarchical = ['BTC', 'DASH'];
  public solanaInflationStakingRewardsEnabled = true;
  public solanaInflationStakingRewardsStartTime: string | null = null;
  public solanaInflationStakingRewardsEndTime: string | null = null;
  public trackingModes = [
    { value: null, label: 'None', enabled: true },
    { value: 'transaction_balance', label: 'Transaction + Balance', enabled: false },
    { value: 'balance', label: 'Balance', enabled: false },
    { value: 'balance_delta', label: 'Balance Delta', enabled: true },
  ];

  public syncStartDateSEC = 0;

  public addressType = 'Simple';
  public addressTypes = [
    {
      name: 'Simple',
      id: 'Simple',
    },
    {
      name: 'Hierarchical Deterministic (HD, XPUB, etc.)',
      id: 'XPUB',
    },
  ];

  public trackMode = 'transaction_balance';

  // add metadata field for specific blockchains here
  public readonly blockchainMetadataFields: { [key: string]: MetadataField[] } = {
    xrp: [{ id: 'DestinationTag', label: 'Destination Tag', featureFlag: 'XRPDestinationTagWallets' }],
    // canton: [
    //   { id: 'FeeType', label: 'Fee Type', featureFlag: 'canton' },
    //   { id: 'TransactionType', label: 'Transaction Type', featureFlag: 'canton' },
    //   { id: 'RewardType', label: 'Reward Type', featureFlag: 'canton' },
    // ],
  };

  // define blockchain-specific address regex here:
  public readonly blockchainAddressValidationMap: Readonly<Record<string, BlockchainValidations>> = {
    polyx: {
      regex: '^0x[a-fA-F0-9]{64}$',
      validationMessage:
        'A valid Decentralized ID should be provided. Expected format: 0x followed by 64 hexadecimal characters.',
    },
  };

  public get canBeHeirarchical() {
    return this.heirarchical.includes(this.blockchain);
  }

  public get isHeirarchical() {
    return !!this.canBeHeirarchical && this.addressType !== 'Simple';
  }

  public get metadataFields() {
    return this.blockchainMetadataFields[this.blockchain]?.filter(
      (field) => !field.featureFlag || this.checkFeatureFlag(field.featureFlag)
    );
  }

  public showMetadata = false;
  public selectedMetadataField: string | null = null;
  public metadataInput = '';

  public async validateWalletAddress() {
    const watchWallets = this.watchWallets;
    if (watchWallets.includes(this.blockchain) && !this.isHeirarchical) {
      // watch wallet validation
      const variables = {
        networkId: this.blockchain.toString().toLowerCase(),
        address: this.walletAddress,
      };
      const query = await validWalletAddress;
      const response = (await this.$apollo.query({
        query,
        variables,
      })) as { data?: { isAddressValid?: IsValidAddress | null } | null };
      this.isValid = response?.data?.isAddressValid?.isValid ?? false;
    } else {
      // adding regex validation for blockchain address
      const validations = this.blockchainAddressValidationMap[this.blockchain];
      if (validations && validations.regex) {
        this.isValid = new RegExp(validations.regex).test(this.walletAddress);
      } else {
        // by default, no validations if no regex is defined for the blockchain.
        this.isValid = !!this.walletAddress.trim();
      }
    }
  }

  get validationMessage(): string {
    const validations = this.blockchainAddressValidationMap[this.blockchain];
    if (validations && validations.validationMessage) {
      return validations.validationMessage;
    } else {
      // a default validation message
      return 'Invalid Address';
    }
  }

  public onDateChange(
    field: 'solanaInflationStakingRewardsStartTime' | 'solanaInflationStakingRewardsEndTime',
    date: string | null
  ) {
    if (date === null || this._isValidDate(date)) {
      this[field] = date;
    }
  }

  private _isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);
    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  get customRollup() {
    return this.rollupSelector === 'custom';
  }

  get canRollup() {
    const s = new Set([
      'eth',
      'immutable',
      'klaytn',
      'sol',
      'flow',
      'hbar',
      'apt',
      'xrp',
      'polygon',
      'dot',
      'op',
      'gnosis',
      'ksm',
      'osmosis',
      'cosmos',
      'arb',
      'avaxc',
      'base',
      'bscAbbs',
      'optimism',
      'polygonAbbs',
      'avalancheAbbs',
      'canton',
      'avaxp',
    ]);
    return s.has(this.blockchain.toString());
  }

  public get isImmutableXConfig() {
    const s = new Set(['immutable']);
    return s.has(this.blockchain.toString().toLowerCase()) && this.checkFeatureFlag('immutable-config', this.features);
  }

  public get hasTrackingMode() {
    const walletTypeSet = new Set(['canton']);
    return walletTypeSet.has(this.blockchain.toLowerCase().toString());
  }

  get hasAdvancedConfig() {
    return this.checkFeatureFlag('advanced-config', this.features);
  }

  get networkTrackingModes() {
    if (this.blockchain.toLowerCase().toString() === 'canton') {
      this.trackingModes.map((tm) => {
        if (tm.value === 'none') return tm;
        if (tm.value === 'balance_delta') tm.enabled = true;
        // else tm.enabled = false;
        return tm;
      });
    }

    return this.trackingModes.filter((tm) => tm.enabled);
  }

  get defaultTrackMode() {
    if (this.blockchain.toLowerCase().toString() === 'canton') {
      return null;
    }
    return this.trackMode;
  }

  public input() {
    this.debouncedValidate();

    const metadata = this.selectedMetadataField
      ? { [this.selectedMetadataField]: this.metadataInput || undefined }
      : {};

    this.$emit('data', {
      walletAddress: this.walletAddress,
      immutableXConfig: this.isImmutableXConfig ? this.immutableXConfig : undefined,
      rollup: this.canRollup ? this.rollup : undefined,
      rollupSelector: this.canRollup ? this.rollupSelector : undefined,
      rollupPeriod: this.canRollup ? this.rollupPeriod : undefined,
      rollupRevenue: this.canRollup ? this.rollupRevenue : undefined,
      rollupTokenRevenue: this.canRollup ? this.rollupTokenRevenue : undefined,
      rollupFee: this.canRollup ? this.rollupFee : undefined,
      rollupTokenRemittance: this.canRollup ? this.rollupTokenRemittance : undefined,
      rollupRemittance: this.canRollup ? this.rollupRemittance : undefined,
      rollupAll: this.canRollup ? this.rollupAll : undefined,
      seperateByAccount: this.canRollup ? this.seperateByAccount : undefined,
      logSelector: this.canRollup ? this.logSelector : undefined,
      accountCheck: this.canRollup ? this.accountCheck : undefined,
      metadata,
      trackingMode: this.hasTrackingMode ? this.trackMode : null,
      addressType: this.isHeirarchical ? this.addressType : 'Simple',
      syncStartDateSEC: this.syncStartDateSEC,
      // solanaInflationStakingRewardsEnabled:
      //   this.blockchain === 'sol' ? this.solanaInflationStakingRewardsEnabled ?? undefined : undefined,
      // solanaInflationStakingRewardsStartTime:
      //   this.blockchain === 'sol' ? this.solanaInflationStakingRewardsStartTime ?? undefined : undefined,
      // solanaInflationStakingRewardsEndTime:
      //   this.blockchain === 'sol' ? this.solanaInflationStakingRewardsEndTime ?? undefined : undefined,
    });
  }

  async addAccount() {
    this.accountCheck.push('<involved account address>');
    this.input();
  }

  async addLogSelector() {
    this.logSelector.push('log string like');
    this.input();
  }

  get validForm() {
    return (
      this.isValid && this.walletAddress.length > 0 && (!this.rollup || (this.rollupPeriod && this.rollupSelector))
    );
  }

  public onToggleMetadata(show: boolean) {
    if (!show) {
      this.selectedMetadataField = null;
      this.metadataInput = '';
    }
  }

  public onMetadataFieldChange() {
    this.metadataInput = '';
  }

  @Watch('walletAddress')
  @Watch('rollup')
  @Watch('rollupSelector')
  @Watch('rollupPeriod')
  @Watch('rollupRevenue')
  @Watch('rollupTokenRevenue')
  @Watch('rollupFee')
  @Watch('rollupTokenRemittance')
  @Watch('rollupRemittance')
  @Watch('rollupAll')
  @Watch('seperateByAccount')
  @Watch('logSelector')
  @Watch('accountCheck')
  @Watch('immutableXConfig')
  @Watch('solanaInflationStakingRewardsEnabled')
  @Watch('solanaInflationStakingRewardsStartTime')
  @Watch('solanaInflationStakingRewardsEndTime')
  @Watch('metadataInput')
  @Watch('syncStartDateSEC')
  public onWalletTypeChanged() {
    this.input();
  }

  @Watch('isValid')
  @Watch('validForm')
  public validChanged() {
    this.$emit('valid', this.validForm);
  }

  @Watch('blockchain')
  onBlockchainChange() {
    this.selectedMetadataField = null;
    this.metadataInput = '';

    // ensure that the metadata field changes are emitted
    this.input();
  }
}
